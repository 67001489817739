import styled from 'styled-components';

const NavigationStyle = styled.nav`
	--gap: var(--sp2x);

	--dropdownColDistance: var(--sp2x);
	--dropdownInnerColBorderRadius: var(--sp3x);

	--dropdownPadding: var(--sp4x);
	--dropdownBorderRadius: var(--sp7x);
	--dropdownAbsoluteMTop: var(--sp1x);
	--dropdownInnerColPadTB: var(--sp3x);
	--dropdownInnerColPadLR: var(--sp3x);

	--dropdownItemGap: var(--sp3x);

	.item-title-wrap {
		display: flex;
		align-items: center;
		gap: var(--gap);

		&.active {
			cursor: default;

			.link-navigation {
				/* background-position: 100% 100%;
				background-size: 100% 2px; */
				color: var(--orange1200Color);
				pointer-events: none;
				cursor: default;
			}
		}
	}

	.dropdown-absolute-wrapper {
		width: 100vw;
		max-height: 0;
		position: absolute;
		top: 100%;
		left: 0;
		margin-top: var(--dropdownAbsoluteMTop);
		overflow: hidden;
		border-radius: var(--dropdownBorderRadius);
		box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
		pointer-events: none;
		z-index: 4;
	}

	.inner-wrapper {
		padding: var(--dropdownPadding);
		background-color: var(--whiteColor);
	}

	.dropdown-container {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(-1 * calc(var(--dropdownColDistance) / 2));
		row-gap: var(--dropdownItemGap);
	}

	.dropdown-col {
		width: 50%;
		padding: 0 calc(var(--dropdownColDistance) / 2);
	}

	.dropdown-inner-col {
		padding: var(--dropdownInnerColPadTB) var(--dropdownInnerColPadLR);
		border-radius: var(--dropdownInnerColBorderRadius);
		transition: background-color var(--trTime) ease-out, color var(--trTime) ease-out;
	}

	@media (hover: hover) {
		.dropdown-inner-col {
			&:hover {
				background-color: var(--surfaceColor);
			}
		}
	}

	.link-item {
		&.active {
			pointer-events: none;
			cursor: default;

			.dropdown-inner-col {
				color: var(--orange1200Color);
			}
		}
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--gap: var(--sp1x);
		--dropdownPadding: var(--sp3x);
		--dropdownBorderRadius: var(--sp6x);

		--dropdownAbsoluteMTop: 0;
		--dropdownColDistance: var(--sp1x);
		--dropdownInnerColPadTB: var(--sp2x);
		--dropdownInnerColPadLR: var(--sp3x);
		--dropdownInnerColBorderRadius: var(--sp2x);

		--dropdownItemGap: var(--sp2x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--gap: var(--sp1x);
		--dropdownPadding: var(--sp3x);
		--dropdownBorderRadius: var(--sp5x);

		--dropdownAbsoluteMTop: var(--sp0-5x);
		--dropdownColDistance: var(--sp1x);
		--dropdownInnerColPadTB: var(--sp2x);
		--dropdownInnerColPadLR: var(--sp2x);
		--dropdownInnerColBorderRadius: var(--sp2x);

		--dropdownItemGap: var(--sp2x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--gap: var(--sp1x);
		--dropdownPadding: var(--sp3x);
		--dropdownBorderRadius: var(--sp4x);

		--dropdownAbsoluteMTop: var(--sp0-5x);
		--dropdownColDistance: var(--sp1x);
		--dropdownInnerColPadTB: var(--sp1-5x);
		--dropdownInnerColPadLR: var(--sp2-5x);
		--dropdownInnerColBorderRadius: var(--sp2x);

		--dropdownItemGap: var(--sp2x);
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--gap: var(--sp1x);
		--dropdownPadding: var(--sp3x);
		--dropdownBorderRadius: var(--sp5x);

		--dropdownAbsoluteMTop: 0;
		--dropdownColDistance: var(--sp1x);
		--dropdownInnerColPadTB: var(--sp1-5x);
		--dropdownInnerColPadLR: var(--sp1-5x);
		--dropdownInnerColBorderRadius: var(--sp2x);

		--dropdownItemGap: var(--sp2x);
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--gap: var(--sp1x);
		--dropdownPadding: 0;
		--dropdownBorderRadius: 0;

		--dropdownAbsoluteMTop: 0;
		--dropdownColDistance: 0;
		--dropdownInnerColPadTB: 0;
		--dropdownInnerColPadLR: 0;
		--dropdownInnerColBorderRadius: 0;

		--dropdownItemGap: 0;
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--gap: var(--sp1x);
		--dropdownPadding: 0;
		--dropdownBorderRadius: 0;

		--dropdownAbsoluteMTop: 0;
		--dropdownColDistance: 0;
		--dropdownInnerColPadTB: 0;
		--dropdownInnerColPadLR: 0;
		--dropdownInnerColBorderRadius: 0;
	}
`;

export default NavigationStyle;
