'use client';

import { useEffect, useState, useRef } from 'react';
import gsap from 'gsap';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import PreloaderStyle from './style';

const Preloader = ({ preloader, disablePreloader }) => {
	//! Ref
	const contRef = useRef();

	//! State
	const [percent, setPercent] = useState(0);

	//! Did Mount
	useEffect(() => {
		let num = 0;

		function imgLoad(img) {
			let imgL = document.images.length;
			img.src = img.getAttribute('src');

			setTimeout(function () {
				setPercent(Math.ceil((imgL === 1 ? 1 : num / (imgL - 1)) * 100));

				num++;

				if (num < imgL) {
					imgLoad(document.images[num]);
				}
			}, 50);
		}

		imgLoad(document.images[num]);
	}, []);

	useEffect(() => {
		if (percent === 100) {
			gsap.to(contRef.current, { duration: 0.9, y: '100%', onComplete: () => disablePreloader() });
		}
	}, [percent]);

	useEffect(() => {
		if (preloader) {
			document.body.classList.add('hidden');
		} else {
			document.body.classList.remove('hidden');
		}
	}, [preloader, percent]);

	return preloader ? <PreloaderStyle ref={contRef} /> : null;
};

export default withUIContext(Preloader, ['preloader', 'disablePreloader']);
