import styled from 'styled-components';

const LanguageSwitcher = styled.div`
	--langDropBorderRad: var(--sp2x);
	--langItemPad: var(--sp1-5x);

	--langDropdownContentTop: calc(200% + var(--sp1x));

	.selected-lang-wrap {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;

		.lang-text {
			transition: color var(--trTime) ease-out;
		}

		.language-dropdown-icon {
			margin-left: var(--sp1-5x);
			transition: rotate var(--trTime) ease-out, color var(--trTime) ease-out;

			&.active {
				rotate: -180deg;
			}
		}

		.dropdown {
			width: 100%;
			position: absolute;
			top: var(--langDropdownContentTop);
			left: 50%;
			transform: translateX(-50%);
			box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
			background-color: var(--whiteColor);
			border-radius: var(--langDropBorderRad);
			z-index: 10;

			.lang-item {
				text-align: center;
				padding: var(--langItemPad);
				text-transform: uppercase;
			}
		}

		@media (hover: hover) {
			&:hover {
				.lang-text,
				i {
					color: var(--orange1200Color);
				}
			}
		}
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--langDropdownContentTop: 200%;
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--langDropdownContentTop: calc(100% + var(--sp2-5x));
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--langDropdownContentTop: calc(100% + var(--sp2-5x));
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--langDropdownContentTop: calc(100% + var(--sp2x));
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--langDropdownContentTop: calc(100% + var(--sp2-5x));
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--langDropdownContentTop: calc(100% + var(--sp3x));
		--langDropBorderRad: var(--sp1-5x);
		--langItemPad: var(--sp1x);
	}
`;

export default LanguageSwitcher;
