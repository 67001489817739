'use client';

import dynamic from 'next/dynamic';
import { useEffect, useMemo, useRef, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import gsap, { ScrollTrigger } from 'gsap/all';
import { useGSAP } from '@gsap/react';

//* HOC's
import { withDataContext, withLanguageContext, withUIContext } from '@/context';

//* Components
import Text from '../Text';
import Icon from '../Icon';
import Button from '../Button';
import CustomLink from '../CustomLink';
import Navigation from '../Navigation';
import FormItem from '../Form/FormItem';
import FormContainer from '../Form/FormContainer';

const FormInput = dynamic(() => import('../Form/FormInput'));

//* Style
import FooterStyle from './style';

const AppFooter = ({ translate, globalData, subscribe, preloader, updateFooterState }) => {
	//! Next Navigation
	const pathname = usePathname();
	const searchParams = useSearchParams();

	//! Refs
	const footerRef = useRef();
	const footerWrapperRef = useRef();
	const fakParallax = useRef();
	const commonWrapperParallax = useRef();
	const parallaxWrapper = useRef();

	//! States
	const [isTouchScreen, setIsTouchScreen] = useState(false);
	const [isSuccess, setIsSuccess] = useState();
	const [mobileParallax, setIzMobileParallax] = useState(false);

	//! Handle Finish
	const handleSubscribe = (values) => {
		setIsSuccess('loading');

		return subscribe({ email: values.subscribe_email })
			.then(() => {
				setIsSuccess(true);

				setTimeout(() => {
					setIsSuccess(false);
				}, 3000);

				return Promise.resolve();
			})
			.catch((err) => {
				setIsSuccess(false);

				err['data']['errors']['subscribe_email'] = err['data']['errors']['email'];

				return Promise.reject(err);
			});
	};

	//! Check
	const isInteractiveMap = useMemo(() => pathname.includes('/interactive-map/'), [pathname]);

	//! Touch Screen Desktop
	useEffect(() => {
		if (typeof window !== 'undefined' && 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) {
			setIsTouchScreen(true);
		}
	}, [isTouchScreen]);

	//! Parallax footer Desktop with Smooth Scroll
	useGSAP(
		() => {
			if (!isInteractiveMap) {
				//? size footer
				const { height } = footerRef?.current?.getBoundingClientRect();

				if (!isTouchScreen) {
					//?add touch fixed
					footerRef.current.classList.add('footer-wrapper-position');

					//? delete other trigger
					const trigger = ScrollTrigger.getById('uniqueFooterTrigger');

					if (trigger) {
						trigger.kill();
					}

					//? register trigger
					gsap.registerPlugin(ScrollTrigger);

					if (footerRef?.current != undefined || footerRef?.current != null) {
						ScrollTrigger.create({
							id: 'uniqueFooterTrigger',
							pin: true,
							pinSpacing: true,
							trigger: '.footer-container',
							start: 'bottom bottom',
							end: `bottom bottom-=${height}px`,
							// markers: true,
							scrub: true,
						});
					}
				} else {
					const maine = document.querySelector('main');

					maine.style.cssText = `z-index:2`;
				}
			}
		},
		{ scope: footerWrapperRef, dependencies: [pathname, isTouchScreen, isInteractiveMap, searchParams.get('regionId'), searchParams.get('categoryId'), searchParams.get('page'), preloader, updateFooterState] }
	);

	//! Mobile Parallax wrapepr
	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				if (isTouchScreen) {
					if (commonWrapperParallax.current?.clientHeight) {
						setIzMobileParallax(commonWrapperParallax.current?.clientHeight);
					}
				}
			}
		});

		if (commonWrapperParallax.current) {
			resizeObserver.observe(commonWrapperParallax.current);
		}

		if (isTouchScreen) {
			ScrollTrigger.create({
				trigger: footerWrapperRef.current,
				start: `top bottom`,
				end: `bottom bottom-=${mobileParallax}`,
				// markers: true,
				once: false,
				id: 'footer-scroll',
				onEnter: () => {
					gsap.to('.footer-parallax-mobile', {
						opacity: 1,
						duration: 0,
					});
				},
				onLeaveBack: () => {
					gsap.to('.footer-parallax-mobile', {
						opacity: 0,
						duration: 0,
					});
				},
			});
		}

		return () => {
			if (commonWrapperParallax.current) {
				resizeObserver.unobserve(commonWrapperParallax.current);
			}
			if (isTouchScreen) {
				const trigger = ScrollTrigger.getById('footer-scroll');
				if (trigger) {
					trigger.kill();
				}
			}
		};
	});

	return !isInteractiveMap ? (
		<div
			ref={footerWrapperRef}
			className={`${pathname.includes('/regions/') || pathname.includes('/the-trail/') ? 'surface-color-bg' : ''}`}>
			<FooterStyle className={`${isTouchScreen ? '' : 'footer-no-touchscreen'} footer-container`}>
				<div ref={footerRef}>
					<div
						ref={commonWrapperParallax}
						className={`common-wrapper ${isTouchScreen ? 'footer-parallax-mobile' : ''}`}>
						<div className='wrapper-top row'>
							<div className='search-wrapper col-5 col-t-12'>
								<div className='title-wrapper'>
									<Text
										className={'h3 font-montserrat-medium uppercase'}
										text={'footerTitle'}
									/>

									<Text
										className={'p2 font-active-grotesk-normal'}
										text={'footerText'}
									/>
								</div>

								<div className='contact-wrapper-footer'>
									<FormContainer
										onFinish={handleSubscribe}
										className={'form-container'}>
										<FormItem
											required={false}
											name={'subscribe_email'}
											validationType={'email'}
											className={`subscribe-field ${isSuccess === 'loading' ? 'disabled' : ''}`}>
											<FormInput
												placeholder={'emailPlaceholder'}
												prefix={
													<Button
														ariaLabel={{
															'aria-label': 'Aria Name',
														}}
														type={'submit'}
														className={'contact-arrow'}
														disabled={isSuccess === 'loading'}>
														<Icon
															name={'semicircular-arrow'}
															className={'footer-subscribe-icon'}
														/>
													</Button>
												}
											/>
										</FormItem>

										{isSuccess && isSuccess !== 'loading' ? (
											<Text
												className='p p5 orange-1000-color font-montserrat-regular subscribe-text'
												text={'subscribeSuccessText'}
											/>
										) : null}
									</FormContainer>
								</div>
							</div>

							<div className='col-1 col-t-0' />

							<div className='navigation-wrapper col-6 col-t-12'>
								<Navigation
									className={'footer-navigation'}
									param={'footer'}
								/>

								<Navigation
									className={'footer-navigation-info'}
									param={'info'}
								/>

								{globalData?.socials ? (
									<div className='social-media'>
										{globalData.socials?.instagram ? (
											<CustomLink
												external
												url={globalData.socials.instagram}>
												<Icon
													name={'instagram'}
													className={`h3`}
												/>
											</CustomLink>
										) : null}

										{globalData.socials?.youtube ? (
											<CustomLink
												external
												url={globalData.socials.youtube}>
												<Icon
													name={'youtube'}
													className={`h3`}
												/>
											</CustomLink>
										) : null}

										{globalData.socials?.facebook ? (
											<CustomLink
												external
												url={globalData.socials.facebook}>
												<Icon
													name={'facebook'}
													className={`h3`}
												/>
											</CustomLink>
										) : null}
									</div>
								) : null}
							</div>
						</div>

						<div className='wrapper-bottom'>
							<Text className={'p3 font-active-grotesk-normal'}>
								© {new Date().getFullYear()}, {translate('allRightsReserved')}.
							</Text>

							<Text className={'p3 font-active-grotesk-normal'}>
								{translate('textByConcept')}
								&nbsp;
								<CustomLink
									external
									className={'p3 underline'}
									url={'https://conceptstudio.com/'}>
									Concept Studio
								</CustomLink>
								.
							</Text>
						</div>
					</div>
				</div>
			</FooterStyle>
			{isTouchScreen && mobileParallax ? (
				<div
					style={{ height: mobileParallax }}
					ref={parallaxWrapper}
					className={'mobile-container-footer'}></div>
			) : null}
		</div>
	) : null;
};

export default withUIContext(withDataContext(withLanguageContext(AppFooter, ['translate']), ['globalData', 'subscribe']), ['preloader', 'updateFooterState']);
