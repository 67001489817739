import styled from 'styled-components';

const PreloaderStyle = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--backgroundColor);
	pointer-events: none;
	z-index: 99999999;
`;

export default PreloaderStyle;
