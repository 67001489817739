import styled from 'styled-components';

const HeaderStyle = styled.header`
	--paddingLR: var(--sp17x);
	--paddingTB: var(--sp3x);
	--newWrapGap: var(--sp4x);
	--logoWidth: var(--sp42x);
	--logoHeight: var(--sp11x);

	--regionsDropdownWidth: var(--sp117x);
	--usefulDropdownWidth: var(--sp158x);

	--headerItemsGap: var(--sp4x);
	--layerProportion: 187%;

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	padding: var(--paddingTB) var(--paddingLR);
	background-color: var(--backgroundColor);
	transition: background-color var(--trTime) ease-out;
	opacity: 0;
	z-index: 11;

	& + #smooth-wrapper main,
	& ~ main {
		padding-top: ${(props) => (props.$distance ? 'var(--headerFixedHeight)' : 'unset')};
	}

	.opacity-layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: var(--layerProportion);
		background: linear-gradient(180deg, color(display-p3 0.0863 0.1922 0.3176 / 0.66) 0%, color(display-p3 0.0863 0.1922 0.3176 / 0) 100%);
		opacity: 67%;
		transition: background-image var(--trTime) ease-out;
		pointer-events: none;
		z-index: -1;
	}

	.desktop-navbar,
	.util-wrapper {
		font-size: var(--p3);
		line-height: var(--lineHeightL);
		color: var(--blue1000Color);
		font-family: var(--montserrat_medium);
		font-weight: 500;
	}

	/* .link-navigation {
		display: inline-block;
		padding-bottom: 2px;
		background-image: linear-gradient(var(--blue1000Color) 0 0);
		background-position: 0 100%;
		background-size: 0% 2px;
		background-repeat: no-repeat;
		transition: background-image var(--trTime) ease-out, background-size var(--trTime) ease-out, background-position 0s var(--trTime) ease-out;
	} */

	.link-navigation {
		transition: color var(--trTime) ease-out;
	}

	.wrapper-header {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	.nav-wrapper {
		display: flex;
		align-items: center;
		gap: var(--headerItemsGap);
	}

	.util-wrapper {
		display: flex;
		align-items: center;
	}

	.logo {
		--proportion: unset;

		width: var(--logoWidth);
		height: var(--logoHeight);

		&.rgb-logo {
		}
	}

	.util-item-distance {
		margin-right: var(--newWrapGap);
	}

	.header-icon {
		font-size: var(--h5);
	}

	.util-wrapper > a {
		display: flex;
		align-items: center;
	}

	.burger-wrap {
		display: none;
	}

	.item-title-wrap {
		position: relative;
	}

	.item-title-wrap.before::before {
		content: '';
		display: block;
		width: 100%;
		height: calc(100% * 3);
		position: absolute;
		left: 0px;
		top: 100%;
		pointer-events: none;
		background-color: transparent;
		opacity: 0;
		z-index: 3;
		cursor: default;
	}

	.drop-icon {
		transition: rotate var(--trTime) ease-out, color var(--trTime) ease-out;
	}

	.dropdown-absolute-wrapper {
		&.regions-dropdown-wrapper {
			max-width: var(--regionsDropdownWidth);
			left: calc(50% - var(--regionsDropdownWidth) / 2);
		}

		&.useful-dropdown-wrapper {
			max-width: var(--usefulDropdownWidth);
			left: calc(50% - var(--usefulDropdownWidth) / 2);
		}

		&.active {
			animation: dropdownAnim 1s ease-out forwards;
		}
	}

	.header-map-icon,
	.header-search-icon {
		i {
			transition: color var(--trTime) ease-out;
		}

		&.active {
			i {
				color: var(--orange1200Color);
			}
		}
	}

	/* //! Reverse version */
	&.transparent {
		background-color: transparent;

		.desktop-navbar,
		.util-wrapper {
			color: var(--backgroundColor);
		}

		/* .link-navigation {
			background-image: linear-gradient(var(--backgroundColor) 0 0);
		} */
	}

	.drop-icon {
		&.active {
			rotate: -180deg !important;
		}
	}

	@media (hover: hover) {
		.every-navigation:hover {
			.item-title-wrap.before::before {
				opacity: 1;
				pointer-events: all;
			}

			.item-title-wrap {
				/* .link-navigation {
					background-position: 100% 100%;
					background-size: 100% 2px;
				} */

				.link-navigation {
					color: var(--orange1200Color);
				}

				.drop-icon {
					color: var(--orange1200Color);
				}
			}
		}

		.header-map-icon,
		.header-search-icon {
			&:hover {
				i {
					color: var(--orange1200Color);
				}
			}
		}
	}

	@keyframes dropdownAnim {
		0% {
			max-height: 0;
		}

		100% {
			max-height: 50vh;
			pointer-events: all;
		}
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--paddingLR: var(--sp12x);
		--paddingTB: var(--sp3x);
		--newWrapGap: var(--sp3x);
		--logoWidth: var(--sp31x);
		--logoHeight: var(--sp8x);

		--regionsDropdownWidth: var(--sp88x);
		--usefulDropdownWidth: var(--sp103x);

		--headerItemsGap: var(--sp3x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--paddingLR: var(--sp10x);
		--paddingTB: var(--sp2x);
		--newWrapGap: var(--sp3x);
		--logoWidth: var(--sp23x);
		--logoHeight: var(--sp6x);

		--regionsDropdownWidth: var(--sp70x);
		--usefulDropdownWidth: var(--sp99x);

		--headerItemsGap: var(--sp3x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--paddingLR: var(--sp8x);
		--paddingTB: var(--sp2x);
		--newWrapGap: var(--sp3x);
		--logoWidth: var(--sp23x);
		--logoHeight: var(--sp6x);

		--regionsDropdownWidth: var(--sp62x);
		--usefulDropdownWidth: var(--sp89x);

		--headerItemsGap: var(--sp3x);
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--paddingLR: var(--sp6x);
		--paddingTB: var(--sp2x);
		--newWrapGap: var(--sp3x);
		--logoWidth: var(--sp19x);
		--logoHeight: var(--sp5x);

		--regionsDropdownWidth: var(--sp60x);
		--usefulDropdownWidth: var(--sp85x);

		--headerItemsGap: var(--sp3x);
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--paddingLR: var(--sp4x);
		--paddingTB: var(--sp1-5x);
		--newWrapGap: var(--sp3x);
		--logoWidth: var(--sp19x);
		--logoHeight: var(--sp5x);

		--regionsDropdownWidth: 0;
		--usefulDropdownWidth: 0;

		--headerItemsGap: 0;

		.desktop-navbar,
		.header-map-icon,
		.header-search-icon {
			display: none !important;
		}

		.desktop-lang {
			margin-right: var(--newWrapGap);
		}

		.burger-wrap {
			display: block;
			width: var(--sp4x);
			height: var(--sp4x);
			cursor: pointer;

			&.reverse-color {
				path {
					fill: var(--blue1000Color);
				}
			}
		}
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--paddingTB: var(--sp2x);
		--paddingLR: var(--sp2x);
		--newWrapGap: var(--sp3x);
		--logoWidth: var(--sp15x);
		--logoHeight: var(--sp4x);

		--regionsDropdownWidth: 0;
		--usefulDropdownWidth: 0;

		--headerItemsGap: 0;

		--layerProportion: 118%;

		.desktop-navbar,
		.header-map-icon,
		.header-search-icon {
			display: none !important;
		}

		.desktop-lang {
			margin-right: var(--newWrapGap);
		}

		.burger-wrap {
			display: block;
			width: var(--sp3-5x);
			height: var(--sp3-5x);
			cursor: pointer;

			&.reverse-color {
				path {
					fill: var(--blue1000Color);
				}
			}
		}
	}
`;

export default HeaderStyle;
