import { usePathname } from 'next/navigation';

//* HOC's
import { withLanguageContext, withUIContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Lottie
import burgerWhiteLottie from '@/lottieFiles/Burger_Lottie_White.json';

//* Components
import Icon from '@/components/global/Icon';
import CustomLink from '@/components/global/CustomLink';
import LottieAnimation from '@/components/global/LottieAnimation';

//* Style
import BottomTabNavigationStyle from './style';

const BottomTabNavigation = ({ toggleBurgerMenu, isBurgerMenuOpen, selectedLang }) => {
	//! Next Navigation
	const pathname = usePathname();

	return (
		<BottomTabNavigationStyle>
			<CustomLink
				className={`mobile-bottom-tab-icon map ${pathname.replace(selectedLang + '/', '') == config.routes.interactiveMap.path ? 'active' : ''}`}
				url={config.routes.interactiveMap.path}>
				<Icon name={'map'} />
			</CustomLink>

			<div
				className={`middle-icon`}
				onClick={() => toggleBurgerMenu()}>
				<LottieAnimation
					loop={false}
					autoplay={false}
					isPlay={isBurgerMenuOpen}
					isReverse={!isBurgerMenuOpen}
					animData={burgerWhiteLottie}
				/>

				<Icon
					name={'burger_background'}
					className={'background-absolute'}
				/>
			</div>

			<CustomLink
				className={`mobile-bottom-tab-icon search ${pathname.replace(selectedLang + '/', '') == config.routes.search.path ? 'active' : ''}`}
				url={config.routes.search.path}>
				<Icon name={'search'} />
			</CustomLink>
		</BottomTabNavigationStyle>
	);
};

export default withLanguageContext(withUIContext(BottomTabNavigation, ['toggleBurgerMenu', 'isBurgerMenuOpen']), ['selectedLang']);
