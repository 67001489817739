import styled from 'styled-components';

const ImageStyle = styled.div`
	--proportion: 50%;

	transition: background-color 0.2s ease-out;

	&.image-cont {
		position: relative;
		padding-top: var(--proportion);

		img {
			object-fit: cover;
			object-position: center;
			transition: opacity 0.5s ease-out, clip-path var(--trTime) ease-out;
		}
	}

	&.contain {
		img {
			object-fit: contain;
		}
	}
`;

export default ImageStyle;
