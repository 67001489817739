import { useEffect, useRef, useCallback, useState } from 'react';
import { useParams, usePathname } from 'next/navigation';
import gsap from 'gsap';

//* HOC's
import { withLanguageContext } from '@/context';

//* Components
import Text from '@/components/global/Text';
import CustomLink from '@/components/global/CustomLink';

//* Style
import MenuItemCollapseStyle from './style';

const MenuItemCollapse = ({ title, url, children, selectedLang }) => {
	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Refs
	const collapseRef = useRef();
	const bodyRef = useRef();

	//! State
	const [open, setOpen] = useState();

	//! Window Click Listener for close
	const handleWindowClick = useCallback(
		(e) => {
			if (!collapseRef.current.contains(e.target)) {
				setOpen(false);
			}
		},
		[open]
	);

	//! Did Mount
	useEffect(() => {
		gsap.set(bodyRef.current, { height: 0 });

		window.addEventListener('click', handleWindowClick);

		return () => {
			window.removeEventListener('click', handleWindowClick);
		};
	}, []);

	//! Toggle Body
	useEffect(() => {
		gsap.to(bodyRef.current, { duration: 0.3, height: open ? 'auto' : 0 });
	}, [open]);

	//! Handle Collapse Click
	const handleCollapseClick = useCallback(() => {
		setOpen(!open);
	}, [open]);

	//! Default state before refresh
	useEffect(() => {
		gsap.set(bodyRef.current, { height: 0 });
	}, [pathname, params.lang]);

	return children?.length > 0 ? (
		<MenuItemCollapseStyle
			ref={collapseRef}
			className={open ? 'active' : ''}>
			<div
				className='collapse-header'
				onClick={handleCollapseClick}>
				<Text
					className={`h5 font-montserrat-medium blue-1000-color collapse-title`}
					text={title}
				/>
				<div className={`toggle-icon`}>
					<svg
						x='0px'
						y='0px'
						version='1.1'
						viewBox='0 0 70.66 71.06'>
						<g>
							<path
								className='anim-path'
								d='M38.76,39.41c-0.03,2.03-0.03,4.07-0.04,6.1c-0.01,1.22-0.13,2.43-0.36,3.64c-0.14,0.74-0.46,1.38-0.94,1.95
		c-0.88,1.02-2.38,1.38-3.55,0.82c-1.04-0.49-1.61-1.36-1.82-2.45c-0.11-0.64-0.14-1.3-0.14-1.94c0-1.55,0.04-3.09,0.06-4.64
		c0.02-1.3,0.54-8.88,0.55-10.2v-0.01c0.13-1.13,0.26-2.26,0.37-3.39c0.22-2.11,0.4-4.21,0.64-6.31c0.1-0.89,0.28-1.77,0.51-2.63
		c0.1-0.38,0.37-0.73,0.63-1.02c0.52-0.58,1.62-0.6,2.23-0.11c0.4,0.32,0.69,0.71,0.85,1.17c0.32,0.93,0.53,1.87,0.61,2.86
		c0.19,2.59,0.42,5.19,0.4,7.79C38.7,31.55,38.77,38.93,38.76,39.41z'
							/>
							<path
								d='M51.08,35.88c-0.65,0.69-1.48,1.02-2.38,1.14c-1.77,0.23-3.55,0.39-5.33,0.58c-0.37,0.03-1.18,0.15-1.89,0.27l-10.24,0.74
		H31.2c-0.9,0.03-1.8,0.07-2.71,0.11c-2.12,0.09-4.23,0.01-6.34-0.13c-0.75-0.06-1.45-0.23-2.1-0.58c-0.28-0.15-0.51-0.4-0.71-0.65
		c-0.4-0.52-0.13-1.46,0.48-1.62c0.62-0.16,1.26-0.34,1.9-0.37c1.8-0.09,3.55-0.48,5.32-0.72c1.72-0.24,3.43-0.54,5.14-0.83
		l8.84-1.22l0.1-0.01c0.47-0.05,0.98-0.11,1.49-0.15c2.19-0.18,4.38-0.23,6.56,0.24c0.74,0.16,1.38,0.47,1.91,1
		C51.67,34.27,51.66,35.27,51.08,35.88z'
							/>
						</g>
						<g>
							<path
								d='M0.87,45.44c-0.61-5.86-1.54-10.75-0.14-16.47C3.68,16.95,10.3,8.84,21.57,3.31C25,1.63,29.55,0.58,33.63,0.19
		c3.62-0.35,7.59-0.27,11.24,0.69c6.21,1.63,11.54,4.79,16.2,9.13c2.64,2.47,4.59,5.59,6.09,8.86c1.55,3.37,2.85,7.02,3.33,10.72
		c0.13,1,0.2,2.02,0.18,3.03c-0.24,15.01-8.99,29.52-22.92,35.64c-6.67,2.93-14.34,3.68-21.35,1.72
		c-7.02-1.96-13.29-6.69-16.77-13.09c-0.24-0.45-0.48-0.93-0.46-1.44c0.02-0.51,0.37-1.04,0.88-1.08c0.31-0.02,0.61,0.14,0.87,0.3
		c1.45,0.9,2.71,2.06,4,3.18c3.73,3.25,7.81,6.27,12.53,7.75c8.7,2.72,18.46-0.32,25.52-6.08c10.7-8.71,15.31-22.72,10.86-35.84
		c-1.64-4.82-5.07-9.69-9.27-13.12c-2.52-2.05-5.75-3.78-8.99-4.64C40.63,4.6,35.49,4.56,30.55,5.41c-2.81,0.48-5.79,1.36-8.09,2.61
		C14.34,12.41,8.9,18.12,5.46,26.44c-1.9,4.59-1.98,8.55-2.06,13.29c-0.08,4.74,1.17,9.2,2.53,13.74c0.54,1.79,1.5,3.56,2.35,5.26
		c1.05,2.09,2.51,3.56,4.49,4.8c0.26,0.16,0.53,0.3,0.77,0.5c0.24,0.2,0.35,0.52,0.32,0.83c-0.03,0.31-0.28,0.57-0.56,0.59
		c-0.78,0.05-1.44-0.04-2.12-0.41c-1.01-0.55-1.9-1.2-2.69-2.05c-1.16-1.26-2.14-2.74-2.93-4.25c-1.07-2.07-2.22-4.4-2.93-6.53
		C1.93,50.05,1.08,47.41,0.87,45.44z'
							/>
						</g>
					</svg>
				</div>
			</div>

			<div
				ref={bodyRef}
				className='collapse-body-cont'>
				<div className='collapse-body'>
					<div className='line' />

					{children.map((i, k) => (
						<div
							key={k}
							className={`link-item-cont ${pathname.replace(selectedLang + '/', '') == `${url}${i.slug}/` ? 'active' : ''}`}>
							<CustomLink url={`${url}${i.slug}`}>
								<Text className={`p p4 font-montserrat-regular blue-1000-color link-item-inner-cont`}>{i.title || i.name}</Text>
							</CustomLink>
						</div>
					))}
				</div>
			</div>
		</MenuItemCollapseStyle>
	) : null;
};

export default withLanguageContext(MenuItemCollapse, ['selectedLang']);
