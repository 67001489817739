import styled from 'styled-components';

const BottomTabNavigationStyle = styled.div`
	display: none;

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		position: fixed;
		left: 50%;
		bottom: var(--sp3x);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: var(--sp2x);
		background-color: var(--whiteColor);
		border-radius: var(--sp1-5x);
		border: 1px solid var(--surfaceColor);
		transform: translateX(-50%);
		box-shadow: -3px -3px 12px 0px rgba(0, 0, 0, 0.08), 0px 3px 12px 0px rgba(0, 0, 0, 0.08);
		z-index: 99999;

		.mobile-bottom-tab-icon {
			i {
				font-size: var(--sp4x);
				color: var(--blue1000Color);
				transition: color var(--trTime) ease-out;
			}

			&.map {
				margin-right: var(--sp3-5x);
			}

			&.search {
				margin-left: var(--sp3-5x);
			}

			&.active {
				pointer-events: none;

				i {
					color: var(--orange1200Color);
				}
			}
		}

		.middle-icon {
			position: relative;
			cursor: pointer;

			.lottie-wrap {
				width: var(--sp4x);
				height: var(--sp4x);
			}

			.background-absolute {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: var(--sp6x);
				color: var(--blue1000Color);
				z-index: -1;
			}
		}

		//! Mobile
		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
			.mobile-bottom-tab-icon {
				i {
					font-size: var(--sp3x);
				}

				&.map {
					margin-right: var(--sp3x);
				}

				&.search {
					margin-left: var(--sp3x);
				}
			}

			.middle-icon {
				.lottie-wrap {
					width: var(--sp3x);
					height: var(--sp3x);
				}

				.background-absolute {
					font-size: var(--sp5x);
				}
			}
		}
	}
`;

export default BottomTabNavigationStyle;
