'use client';
import { memo as Memo, useEffect, useMemo, useCallback } from 'react';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Component's
import Icon from '@/components/global/Icon';

//* Style
import PopupStyle from './style';

const Popup = ({ closePopup, popupIsOpen, popupComponent, popupProps }) => {
	const components = useMemo(() => ({}), []);

	const Child = useMemo(() => typeof popupComponent === 'string' && components[popupComponent] && components[popupComponent], [popupComponent]);

	useEffect(() => {
		window.addEventListener('keydown', handleKeydown);

		if ('virtualKeyboard' in navigator) {
			navigator.virtualKeyboard.overlaysContent = true;
		}

		return () => {
			window.removeEventListener('keydown', handleKeydown);
		};
	}, []);

	const handleKeydown = useCallback((e) => {
		if (e.key === 'Escape') {
			closePopup();
		}
	}, []);

	return popupIsOpen ? (
		<PopupStyle className={`${popupProps.className || ''}`}>
			<div
				onClick={closePopup}
				className={`popup-background`}
			/>

			<div className={`popup-content-container`}>
				<div className={`popup-content-wrap`}>
					<div
						onClick={closePopup}
						className={'close-wrap'}>
						<Icon name={`close`} />
					</div>

					{typeof popupComponent === 'string' ? <Child /> : popupComponent}
				</div>
			</div>
		</PopupStyle>
	) : null;
};

export default withUIContext(Popup, ['closePopup', 'popupIsOpen', 'popupComponent', 'popupProps']);
