import { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react';
import Image from 'next/image';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import ImageStyle from './style';

const CustomImage = forwardRef(({ src, alt, className, width, height, priority = false, double, winWidth, onLoad, isOpacityZero = false, onClick }, ref) => {
    //! Ref
    const imgRef = useRef();

    //! State
    const [loaded, setLoaded] = useState(false);

    //! Mobile Desktop
    const doubleSrc = double ? (winWidth >= 1280 ? double?.[0]?.src : double?.[1]?.src) : null;
    const doubleAlt = double ? (winWidth >= 1280 ? double?.[0]?.alt : double?.[1]?.alt) : null;

    useImperativeHandle(ref, () => imgRef.current, []);

    //! Handle Image Load
    const onLoadCallback = () => {
        setLoaded(true);
    };

    useEffect(() => {
        typeof onLoad === 'function' && onLoad(loaded);
    }, [loaded]);

    return (
        <ImageStyle
            ref={imgRef}
            onClick={onClick}
            className={`image-cont ${className || ''}`}
            style={{ width, height, backgroundColor: loaded ? (isOpacityZero ? '#ECEDEE' : 'transparent') : '#ECEDEE' }}>
            <Image
                fill
                draggable={false}
                placeholder='blur'
                priority={priority}
                onLoad={onLoadCallback}
                quality={loaded ? 100 : 50}
                sizes='(max-width: 767px) 100%'
                alt={alt || doubleAlt || 'image'}
                loading={priority ? 'eager' : 'lazy'}
                src={src || doubleSrc || '/images/default.jpeg'}
                blurDataURL={src || doubleSrc || '/images/default.jpeg'}
                style={{ opacity: loaded ? (isOpacityZero ? 0 : 1) : 0 }}
            />
        </ImageStyle>
    );
});

CustomImage.displayName = 'CustomImage';

export default withUIContext(CustomImage, ['winWidth']);
