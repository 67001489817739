import(/* webpackMode: "eager" */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/node_modules/swiper/swiper.min.css");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/node_modules/swiper/modules/pagination/pagination.min.css");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/node_modules/swiper/swiper-bundle.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/src/components/global/AppHeader/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/src/components/global/Popup/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/src/components/global/Preloader/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/src/components/HighOrderComponent/index.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/src/context/providers/DataProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/src/context/providers/LanguageProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/src/context/providers/UIProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["useActiveSection"] */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/src/helpers/_useActiveSection.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/src/libs/styled-registry.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/src/styles/fonts/fonts.css");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/concepttest.club/ant.concepttest.club/frontend/src/styles/icons/style.css");
