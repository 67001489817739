import styled from 'styled-components';

const MenuItemCollapseStyle = styled.div`
	display: none;

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--collapsePaddingDistance: var(--sp3x);

		--linkItemWidth: 50%;
		--linkItemPaddingH: var(--sp1x);
		--linkItemMarginBottom: var(--sp1x);

		--toggleIconSize: var(--sp4x);

		--linkItemInnerContPad: var(--sp1x);

		display: block;

		background-color: var(--whiteColor);
		padding: var(--burgerMenuItemTB) var(--burgerMenuItemLR);
		border-radius: var(--burgerMenuItemBorderRadius);

		path {
			fill: var(--blue1000Color);
		}

		.collapse-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;

			.collapse-title {
				padding-right: var(--sp3x);
			}

			.toggle-icon {
				width: var(--toggleIconSize);
				height: var(--toggleIconSize);
			}
		}

		.collapse-body-cont {
			overflow: hidden;

			.collapse-body {
				display: flex;
				flex-wrap: wrap;
				margin: 0 calc(var(--linkItemPaddingH) * -1) calc(var(--linkItemMarginBottom) * -1);
				padding-top: var(--collapsePaddingDistance);

				.link-item-cont {
					width: var(--linkItemWidth);
					padding: 0 var(--linkItemPaddingH);
					margin-bottom: var(--linkItemMarginBottom);

					.link-item-inner-cont {
						padding: var(--linkItemInnerContPad) 0;
						transition: color var(--trTime) ease-out;
					}

					&.active {
						pointer-events: none;

						.link-item {
							pointer-events: none;
						}

						.link-item-inner-cont {
							color: var(--orange1200Color);
						}
					}
				}
			}
		}

		.line {
			width: 100%;
			height: 1px;
			background-color: var(--neutral100Color);
			margin-bottom: var(--collapsePaddingDistance);
		}

		.anim-path {
			transform-origin: center;
			transition: transform var(--trTime) ease-out;
		}

		&.active {
			.anim-path {
				transform: rotate(90deg);
			}
		}

		//! Mobile
		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
			--collapsePaddingDistance: var(--sp2x);

			--linkItemWidth: 100%;
			--linkItemPaddingH: var(--sp1x);
			--linkItemMarginBottom: var(--sp0-5x);

			--toggleIconSize: var(--sp3x);

			--linkItemInnerContPad: var(--sp1x);
		}
	}
`;

export default MenuItemCollapseStyle;
