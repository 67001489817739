import styled from 'styled-components';

const FooterStyle = styled.footer`
	--paddingLR: var(--sp17x);
	--paddingTB: var(--sp11x);
	--distanceNavigation: var(--sp4x);
	--borderPadDistance: var(--sp11x);
	--distanceLine: var(--sp5x);
	--leftNavigationGap: var(--sp3x);
	--titleGap: var(--sp4x);
	--distanceFormT: var(--sp7x);
	--socialGap: var(--sp3x);

	--footerInputPadTB: var(--sp4x);
	--footerInputPadLR: var(--sp4x);

	position: relative;

	.fak-wrapper-parallax {
		background-color: transparent;
	}

	.footer-parallax-mobile {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		opacity: 0;
	}

	.footer-wrapper-position {
		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	.common-wrapper {
		display: flex;
		flex-direction: column;
		background-color: var(--blue1000Color);
		padding: var(--paddingLR);
		padding-bottom: var(--paddingTB);
		color: var(--whiteColor);
	}

	.navigation-wrapper {
		display: grid;
		column-gap: 10px;
		grid-template-columns: 1fr 1fr;
		height: fit-content;

		.footer-navigation {
			grid-area: 1/1/3/2;
			display: flex;
			flex-direction: column;
			gap: var(--distanceNavigation);

			.link-navigation {
				font-family: var(--montserrat_regular);
				font-size: var(--h5);
				line-height: var(--lineHeightL);
			}
		}

		.footer-navigation-info {
			display: flex;
			flex-direction: column;
			gap: var(--leftNavigationGap);

			.link-navigation {
				font-family: 'aktiv-grotesk-armenian';
				font-weight: 400;
				font-size: var(--p3);
				line-height: var(--lineHeightL);
			}
		}

		.social-media {
			grid-area: 2/2/3/3;
			display: flex;

			.link-item {
				height: fit-content;
				pointer-events: none;
				transition: transform var(--trTime) ease-out;

				i {
					pointer-events: all;
				}

				@media (hover: hover) {
					&:hover {
						transform: scale(1.3);
					}
				}
			}
		}
	}

	.wrapper-top {
		padding-bottom: var(--borderPadDistance);
		border-bottom: 1px solid var(--inverseBlueDarkColor);
	}

	.wrapper-bottom {
		margin-top: var(--distanceLine);
		display: flex;
		justify-content: space-between;
	}

	.title-wrapper {
		display: flex;
		flex-direction: column;
		gap: var(--titleGap);
	}

	.contact-wrapper-footer {
		margin-top: var(--distanceFormT);

		.form-container {
			position: relative;

			.ant-input-affix-wrapper {
				display: flex;
				justify-content: end;
				align-items: center;
				position: relative;

				.ant-input-prefix {
					position: absolute;
				}
			}
		}
	}

	.social-media {
		display: flex;
		gap: var(--socialGap);
	}

	.footer-subscribe-icon {
		font-size: var(--h3);
		color: var(--orange1000Color);
		cursor: pointer;
	}

	.form-container {
		textarea:-webkit-autofill,
		textarea:-webkit-autofill:hover,
		textarea:-webkit-autofill:focus,
		textarea:-webkit-autofill:active,
		select:-webkit-autofill,
		select:-webkit-autofill:hover,
		select:-webkit-autofill:focus,
		select:-webkit-autofill:active,
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active,
		input:-internal-autofill-selected {
			box-shadow: inset 1000px 1000px var(--inverseBlueDarkColor);
			-webkit-text-fill-color: var(--whiteColor);
			transition: background-color 5000s ease-in-out 0s;
		}

		input {
			background-color: var(--inverseBlueDarkColor);
			border: 2px solid var(--inverseBlueDarkColor);
			font-family: var(--montserrat_medium) !important;
			font-weight: 500;
			padding: var(--footerInputPadLR);
			padding-right: calc(var(--h3) + var(--footerInputPadLR) + var(--sp1x));
			color: var(--whiteColor);
			transition: var(--trTime) ease-out border-color, var(--trTime) ease-out background-color;

			&:focus {
				color: var(--whiteColor);
				border-color: var(--whiteColor);
				background-color: var(--inverseBlueDarkColor);
			}
		}

		.ant-input-prefix {
			padding: var(--footerInputPadLR);

			i:before {
				transition: color var(--trTime) ease-out;
			}
		}

		.ant-form-item-has-error {
			.ant-input-prefix {
				.ant-semicircular-arrow:before {
					color: var(--errorColor);
				}
			}
		}
	}

	/* .link-navigation {
		display: inline-block;
		padding-bottom: 2px;
		background-image: linear-gradient(var(--whiteColor) 0 0);
		background-position: 0 100%;
		background-size: 0% 2px;
		background-repeat: no-repeat;
		transition: background-image var(--trTime) ease-out, background-size var(--trTime) ease-out, background-position 0s var(--trTime) ease-out;
	} */

	.link-navigation {
		transition: color var(--trTime) ease-out;
	}

	@media (hover: hover) {
		.underline {
			&:hover {
				text-decoration: none;
			}
		}

		.ant-input-affix-wrapper {
			&:hover {
				input {
					background-color: var(--inverseBlueColor);
					border-color: var(--inverseBlueColor);
				}
			}
		}

		.link-navigation {
			&:hover {
				/* background-position: 100% 100%;
				background-size: 100% 2px; */

				color: var(--orange1200Color);
			}
		}
	}

	/* //! For Subscribe */
	.subscribe-text {
		position: absolute;
		top: 100%;
		transform: translateY(var(--sp1x));
	}

	.subscribe-field {
		&.disabled {
			pointer-events: none;

			input {
				border: 2px solid var(--inverseBlueDarkColor) !important;

				&:focus {
					border-color: var(--inverseBlueDarkColor) !important;
				}
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--paddingLR: var(--sp12x);
		--paddingTB: var(--sp6x);
		--distanceNavigation: var(--sp3x);
		--borderPadDistance: var(--sp8x);
		--distanceLine: var(--sp4x);
		--leftNavigationGap: var(--sp2x);
		--titleGap: var(--sp4x);
		--distanceFormT: var(--sp5x);
		--socialGap: var(--sp2x);

		--footerInputPadTB: var(--sp3x);
		--footerInputPadLR: var(--sp3x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--paddingLR: var(--sp10x);
		--paddingTB: var(--sp5x);
		--distanceNavigation: var(--sp3x);
		--borderPadDistance: var(--sp7x);
		--distanceLine: var(--sp4x);
		--leftNavigationGap: var(--sp2x);
		--titleGap: var(--sp2x);
		--distanceFormT: var(--sp5x);
		--socialGap: var(--sp1-5x);

		--footerInputPadTB: var(--sp2-5x);
		--footerInputPadLR: var(--sp3x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--paddingLR: var(--sp8x);
		--paddingTB: var(--sp4x);
		--distanceNavigation: var(--sp2-5x);
		--borderPadDistance: var(--sp5x);
		--distanceLine: var(--sp4x);
		--leftNavigationGap: var(--sp2x);
		--titleGap: var(--sp2x);
		--distanceFormT: var(--sp4x);
		--socialGap: var(--sp1-5x);

		--footerInputPadTB: var(--sp2-5x);
		--footerInputPadLR: var(--sp2-5x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--paddingLR: var(--sp6x);
		--paddingTB: var(--sp3x);
		--distanceNavigation: var(--sp2-5x);
		--borderPadDistance: var(--sp5x);
		--distanceLine: var(--sp4x);
		--leftNavigationGap: var(--sp2x);
		--titleGap: var(--sp2x);
		--distanceFormT: var(--sp4x);
		--socialGap: var(--sp1-5x);

		--footerInputPadTB: var(--sp2-5x);
		--footerInputPadLR: var(--sp2-5x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--paddingLR: var(--sp4x);
		--paddingTB: var(--sp2x);
		--distanceNavigation: var(--sp2-5x);
		--borderPadDistance: var(--sp6x);
		--distanceLine: var(--sp4x);
		--leftNavigationGap: var(--sp2x);
		--titleGap: var(--sp2x);
		--distanceFormT: var(--sp4x);
		--socialGap: var(--sp1-5x);

		--footerInputPadTB: var(--sp2-5x);
		--footerInputPadLR: var(--sp2-5x);

		.navigation-wrapper {
			padding-top: var(--sp8x);
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--distanceNavigation: var(--sp2x);
		--borderPadDistance: var(--sp5x);
		--distanceLine: var(--sp4x);
		--leftNavigationGap: var(--sp1-5x);
		--titleGap: var(--sp2x);
		--distanceFormT: var(--sp3x);
		--socialGap: var(--sp3x);

		--footerInputPadTB: var(--sp2-5x);
		--footerInputPadLR: var(--sp2-5x);

		.common-wrapper {
			padding: var(--sp2x);
			padding-top: var(--sp4x);
		}

		.navigation-wrapper {
			padding-top: var(--sp8x);
		}

		.wrapper-bottom {
			flex-direction: column;
			gap: var(--sp2x);
		}
	}
`;

export default FooterStyle;
