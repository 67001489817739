'use client';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePathname, useParams, useRouter } from 'next/navigation';
import gsap from 'gsap';

//* HOC's
import { withUIContext, withDataContext, withLanguageContext } from '@/context';

//* Helpers
import config from '@/helpers/_config';

//* Lottie
import burgerBlueLottie from '@/lottieFiles/Burger_Lottie_Blue.json';

//* Style
import HeaderStyle from './style';

//* Components
import Navigation from '../Navigation';
import CustomLink from '../CustomLink';
import BurgerMenu from './BurgerMenu';
import Icon from '@/components/global/Icon';
import Image from '@/components/global/Image';
import LanguageSwitcher from './LanguageSwitcher';
import LottieAnimation from '@/components/global/LottieAnimation';

const AppHeader = ({ toggleBurgerMenu, closeBurgerMenu, isBurgerMenuOpen, globalData, selectedLang, winWidth, preloader }) => {
	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();
	const router = useRouter();

	//! Ref
	const oldScroll = useRef(0);

	//! States
	const [show, setShow] = useState(true);
	const [isTransparent, setIsTransparent] = useState(true);

	//! Close Mobile Menu
	useEffect(() => {
		closeBurgerMenu(false);
	}, [pathname, params.lang]);

	//! Checks
	const isUIKIT = useMemo(() => pathname.includes('/ui-kit/'), [pathname]);
	const isNotFound = useMemo(() => pathname.includes('/not-found/'), [pathname]);
	const isOpacityLayer = useMemo(() => pathname.replace(selectedLang + '/', '') == config.routes.home.path || pathname.replace(selectedLang + '/', '') == config.routes.theTrail.path || pathname.replace(selectedLang + '/', '').split('/').length == 4, [pathname, selectedLang]);
	const isDesktopSize = useMemo(() => winWidth >= 1280, [winWidth]);

	const variants_header = useMemo(() => Object.values(config.routes).filter((item) => item.headerVariant));

	const header_variant = useMemo(() => {
		if (pathname.split('/').includes(params.slug)) {
			let arr = variants_header.filter((item) => item.children);

			arr = arr.filter((i) => pathname.includes(i.path));

			return arr[0].children.headerVariant;
		} else if (isNotFound) {
			return {
				transparent: false,
				distance: true,
			};
		} else if (isUIKIT) {
			return {
				transparent: false,
				distance: true,
			};
		} else if (Object.values(config.routes).filter((item) => pathname.replace(selectedLang + '/', '') == item.path).length > 0) {
			let arr2 = variants_header.filter((item) => pathname.replace(selectedLang + '/', '') == item.path);

			return arr2[0].headerVariant;
		} else {
			return {
				transparent: false,
				distance: true,
			};
		}
	}, [variants_header, pathname, params.slug, isUIKIT, isNotFound, selectedLang]);

	//! First Show
	useEffect(() => {
		if (!preloader) {
			gsap.to('.header-container', { opacity: 1, duration: 1.3, delay: 0.7, ease: 'power2.out' });
		}
	}, [preloader]);

	//! Show / Hide Header
	const onScroll = useCallback(() => {
		if (window.scrollY > oldScroll.current && window.scrollY > 0) {
			setShow(false);
		} else if (window.scrollY < oldScroll.current || window.scrollY <= 0) {
			setShow(true);
		}

		setIsTransparent(pathname.replace(selectedLang + '/', '') == config.routes.home.path ? header_variant.transparent && window.scrollY <= window.innerHeight : header_variant.transparent && window.scrollY <= window.innerHeight * 0.8);

		oldScroll.current = window.scrollY;
	}, [pathname, params.lang, header_variant.transparent]);

	//! Scroll add event listener
	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		isBurgerMenuOpen ? document.body.classList.add('hidden') : document.body.classList.remove('hidden');

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [isBurgerMenuOpen, header_variant.transparent, pathname, params.lang]);

	useEffect(() => {
		onScroll();
	}, [pathname, params.lang, header_variant.transparent]);

	useEffect(() => {
		// gsap.killTweensOf('.header-container');

		// show && gsap.set('.header-container', { display: 'flex' });

		// gsap.to('.header-container', {
		// 	duration: 0.3,
		// 	y: show ? '0%' : '-100%',
		// }).then(() => !show && gsap.set('.header-container', { display: !show ? 'none' : 'flex' }));

		gsap.to('.header-container', {
			duration: 0.3,
			y: show ? '0%' : '-100%',
		});
	}, [show, header_variant.transparent]);

	const handleLogoClick = () => {
		closeBurgerMenu();
		if (pathname !== '/') {
			router.push('/');
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	return (
		<>
			<BurgerMenu isDesktopSize={isDesktopSize} />

			<HeaderStyle
				$distance={header_variant.distance}
				className={`header-container ${isTransparent && !isBurgerMenuOpen ? 'transparent' : ''}`}>
				<div className={`wrapper-header`}>
					<div className='wrapper-logo'>
						{/* <CustomLink url={config.routes.home.path}> */}
						<Image
							priority
							alt={'App logo'}
							onClick={handleLogoClick}
							className={`logo contain cursor-pointer ${isTransparent && !isBurgerMenuOpen ? '' : 'rgb-logo'}`}
							src={isTransparent && !isBurgerMenuOpen ? '/images/Logo-white.svg' : '/images/Logo-blue.svg'}
						/>
						{/* </CustomLink> */}
					</div>

					{isDesktopSize ? (
						<Navigation
							isHeader
							param={'header'}
							className={'desktop-navbar'}
							dropdownData={[globalData.regions, globalData.useful_informations]}
						/>
					) : null}

					<div className='util-wrapper'>
						{isDesktopSize ? (
							<>
								<CustomLink
									className={`util-item-distance header-map-icon ${pathname.replace(selectedLang + '/', '') == config.routes.interactiveMap.path ? 'active' : ''}`}
									url={config.routes.interactiveMap.path}>
									<Icon
										className={'header-icon'}
										name={'map'}
									/>
								</CustomLink>

								<CustomLink
									className={`util-item-distance header-search-icon ${pathname.replace(selectedLang + '/', '') == config.routes.search.path ? 'active' : ''}`}
									url={config.routes.search.path}>
									<Icon
										className={'header-icon'}
										name={'search'}
									/>
								</CustomLink>
							</>
						) : null}

						<div className={'desktop-lang'}>
							<LanguageSwitcher />
						</div>

						{!isDesktopSize ? (
							<div
								className={`burger-wrap ${!isTransparent ? 'reverse-color' : ''}`}
								onClick={() => toggleBurgerMenu()}>
								<LottieAnimation
									loop={false}
									autoplay={false}
									isPlay={isBurgerMenuOpen}
									animData={burgerBlueLottie}
									isReverse={!isBurgerMenuOpen}
								/>
							</div>
						) : null}
					</div>
				</div>

				{isOpacityLayer && isTransparent ? <div className='opacity-layer' /> : null}
			</HeaderStyle>
		</>
	);
};

export default withLanguageContext(withDataContext(withUIContext(AppHeader, ['toggleBurgerMenu', 'closeBurgerMenu', 'isBurgerMenuOpen', 'winWidth', 'preloader']), ['globalData']), ['selectedLang']);
