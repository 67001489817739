import styled from 'styled-components';

const BurgerMenuStyle = styled.div`
	display: none;

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--paddingLR: var(--sp4x);
		--paddingTB: var(--sp1-5x);
		--newWrapGap: var(--sp3x);
		--logoWidth: var(--sp19x);
		--logoHeight: var(--sp5x);

		--burgerContentMTop: var(--sp3x);
		--burgerContentPadLR: var(--sp4x);
		--burgerContentPadB: var(--sp22x);
		--burgerMenuItemTB: var(--sp2-5x);
		--burgerMenuItemLR: var(--sp3x);
		--burgerMenuItemBorderRadius: var(--sp2x);
		--burgerMenuDistance: var(--sp1-5x);

		display: block;

		width: 100vw;
		height: 0;
		position: fixed;
		top: 0;
		left: 0;
		overflow: hidden;
		pointer-events: none;
		transition: height 0.5s ease-out;
		z-index: 15;

		.burger-menu-inner-container {
			background-color: var(--surfaceColor);
			height: 100dvh;
		}

		.burger-content-wrapper {
			margin-top: var(--burgerContentMTop);
			padding: 0 var(--burgerContentPadLR);
			height: calc(100dvh - var(--headerFixedHeight) - var(--burgerContentMTop) - var(--burgerContentPadB));

			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			overflow: hidden auto;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.burger-content-inner-wrapper {
			display: flex;
			flex-direction: column;
			gap: var(--burgerMenuDistance);
		}

		.burger-menu-item {
			padding: var(--burgerMenuItemTB) var(--burgerMenuItemLR);
			border-radius: var(--burgerMenuItemBorderRadius);
			background-color: var(--whiteColor);
			transition: color var(--trTime) ease-out;
		}

		.burger-menu-item-block {
			&.active {
				.burger-menu-item {
					color: var(--orange1200Color);
				}
			}
		}

		.burger-menu-header-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: var(--paddingTB) var(--paddingLR);
		}

		.burger-menu-header-logo {
			.image-cont {
				--proportion: unset;

				width: var(--logoWidth);
				height: var(--logoHeight);
			}
		}

		.burger-menu-util-wrapper {
			display: flex;
			align-items: center;
		}

		.burger-menu-icon-block {
			width: var(--sp4x);
			height: var(--sp4x);
			cursor: pointer;
		}

		.burger-menu-lang {
			margin-right: var(--newWrapGap);
		}

		.selected-lang-wrap {
			color: var(--blue1000Color);
		}

		&.active {
			height: 100dvh;
			pointer-events: all;
		}

		/* //! Mobile */
		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
			--paddingTB: var(--sp2x);
			--paddingLR: var(--sp2x);
			--newWrapGap: var(--sp3x);
			--logoWidth: var(--sp15x);
			--logoHeight: var(--sp4x);

			--burgerContentMTop: var(--sp2x);
			--burgerContentPadLR: var(--sp2x);
			--burgerContentPadB: var(--sp12x);
			--burgerMenuItemTB: var(--sp1-5x);
			--burgerMenuItemLR: var(--sp1-5x);
			--burgerMenuItemBorderRadius: var(--sp2x);
			--burgerMenuDistance: var(--sp1x);

			.burger-menu-icon-block {
				width: var(--sp3-5x);
				height: var(--sp3-5x);
			}
		}
	}
`;

export default BurgerMenuStyle;
