import { useEffect, useMemo, useState } from 'react';
import { useParams, usePathname } from 'next/navigation';

//* HOC's
import { withLanguageContext } from '@/context';

//* Helpers
import config from '@/helpers/_config';

//* Components
import Icon from '../Icon';
import Text from '../Text';
import CustomLink from '../CustomLink';

//* Style
import NavigationStyle from './style';

const Navigation = ({ param, className, translate, dropdownData, isHeader = false, selectedLang }) => {
	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Check className
	const checkClass = (el, cl) => el.classList.contains(cl) || !!el.closest(`.${cl}`);

	//! States
	const [isActiveRegionsDropDown, setIsActiveRegionsDropDown] = useState(false);
	const [isActiveUsefulInfoDropDown, setIsActiveUsefulInfoDropDown] = useState(false);

	//! Detect click outside function
	const handleClickOutside = (e) => {
		if (checkClass(e.target, 'regions-state') && !isActiveRegionsDropDown) {
			setIsActiveRegionsDropDown(true);

			if (isActiveUsefulInfoDropDown) {
				setIsActiveUsefulInfoDropDown(false);
			}
		} else if (!checkClass(e.target, 'regions-dropdown-wrapper') && isActiveRegionsDropDown) {
			setIsActiveRegionsDropDown(false);
		}

		if (checkClass(e.target, 'useful-info-state') && !isActiveUsefulInfoDropDown) {
			setIsActiveUsefulInfoDropDown(true);

			if (isActiveRegionsDropDown) {
				setIsActiveRegionsDropDown(false);
			}
		} else if (!checkClass(e.target, 'useful-dropdown-wrapper') && isActiveUsefulInfoDropDown) {
			setIsActiveUsefulInfoDropDown(false);
		}
	};

	//! Handle scroll
	const handleScroll = () => {
		setIsActiveRegionsDropDown(false);
		setIsActiveUsefulInfoDropDown(false);
	};

	//! Add event listener
	useEffect(() => {
		window.addEventListener('mousedown', handleClickOutside);
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isActiveRegionsDropDown, isActiveUsefulInfoDropDown]);

	//! Pathname & Language change
	useEffect(() => {
		setIsActiveRegionsDropDown(false);
		setIsActiveUsefulInfoDropDown(false);
	}, [pathname, params.lang]);

	const navbarStore = useMemo(() => {
		const links = [];

		for (var key in config.routes) {
			if (config.routes[key].name === 'home' || !config.routes[key][param]) {
				continue;
			}

			links[config.routes[key][param]] = (
				<div
					key={key}
					className={`every-navigation ${config.routes[key].name === 'regions' ? 'regions-state' : ''} ${config.routes[key].name === 'usefulInfo' ? 'useful-info-state' : ''}`}>
					<div className={`item-title-wrap ${pathname.replace(selectedLang + '/', '') == config.routes[key].path && isHeader ? 'active' : ''} ${config.routes[key].arrow && isHeader ? 'before' : ''} ${isHeader ? 'cursor-pointer' : ''}`}>
						{config.routes[key].arrow ? (
							<Text
								className={`link-item link-navigation p p3`}
								text={config.routes[key].name}
							/>
						) : (
							<CustomLink
								className={`link-navigation`}
								url={config.routes[key].path}>
								{translate(config.routes[key].name)}
							</CustomLink>
						)}

						{config.routes[key].arrow && isHeader ? (
							<Icon
								name={'dropdown'}
								className={`drop-icon ${(isActiveRegionsDropDown && config.routes[key].name === 'regions') || (isActiveUsefulInfoDropDown && config.routes[key].name === 'usefulInfo') ? 'active' : ''}`}
							/>
						) : null}
					</div>

					{config.routes[key].arrow && isHeader ? (
						<div className={`dropdown-absolute-wrapper ${config.routes[key].name === 'regions' ? 'regions-dropdown-wrapper' : ''} ${config.routes[key].name === 'usefulInfo' ? 'useful-dropdown-wrapper' : ''} ${(isActiveRegionsDropDown && config.routes[key].name === 'regions') || (isActiveUsefulInfoDropDown && config.routes[key].name === 'usefulInfo') ? 'active' : ''}`}>
							<div className='inner-wrapper'>
								<div className='dropdown-container'>
									{config.routes[key].name === 'regions'
										? dropdownData?.[0].map((region, key) => (
												<div
													key={key}
													className='dropdown-col region-wrapper'>
													<CustomLink
														url={`${config.routes.regions.path}${region.slug}`}
														className={pathname.replace(selectedLang + '/', '') == `${config.routes.regions.path}${region.slug}/` ? 'active' : ''}>
														<Text className={`p p3 font-montserrat-regular blue-1000-color dropdown-inner-col`}>{region.title}</Text>
													</CustomLink>
												</div>
										  ))
										: dropdownData?.[1].map((item, key) => (
												<div
													key={key}
													className='dropdown-col useful-info-item-wrapper'>
													<CustomLink
														url={`${config.routes.usefulInfo.path}${item.slug}`}
														className={pathname.replace(selectedLang + '/', '') == `${config.routes.usefulInfo.path}${item.slug}/` ? 'active' : ''}>
														<Text className={`p p3 font-montserrat-regular blue-1000-color dropdown-inner-col`}>{item.name}</Text>
													</CustomLink>
												</div>
										  ))}
								</div>
							</div>
						</div>
					) : null}
				</div>
			);
		}

		return links;
	}, [param, config, isHeader, dropdownData, translate, selectedLang, isActiveRegionsDropDown, isActiveUsefulInfoDropDown]);

	return <NavigationStyle className={`nav-wrapper ${className || ''}`}>{navbarStore}</NavigationStyle>;
};

export default withLanguageContext(Navigation, ['translate', 'selectedLang']);
