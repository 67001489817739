import { usePathname } from 'next/navigation';

//* HOC's
import { withDataContext, withUIContext, withLanguageContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Lottie
import burgerBlueLottie from '@/lottieFiles/Burger_Lottie_Blue.json';

//* Components
import Text from '../../Text';
import Image from '../../Image';
import CustomLink from '../../CustomLink';
import MenuItemCollapse from './MenuItemCollapse';
import LanguageSwitcher from '../LanguageSwitcher';
import LottieAnimation from '../../LottieAnimation';
import BottomTabNavigation from './BottomTabNavigation';

//* Style
import BurgerMenuStyle from './style';

const BurgerMenu = ({ isBurgerMenuOpen, toggleBurgerMenu, isDesktopSize, globalData, selectedLang }) => {
	//! Next Navigation
	const pathname = usePathname();

	return !isDesktopSize ? (
		<>
			<BurgerMenuStyle className={`burger-menu-container ${isBurgerMenuOpen ? 'active' : ''}`}>
				<div className='burger-menu-inner-container'>
					<div className='burger-menu-header-wrapper'>
						<div className='burger-menu-header-logo'>
							<CustomLink url={config.routes.home.path}>
								<Image
									priority
									alt={'App logo'}
									className={'logo contain'}
									src={'/images/Logo-blue.svg'}
								/>
							</CustomLink>
						</div>

						<div className='burger-menu-util-wrapper'>
							<div className={'burger-menu-lang'}>
								<LanguageSwitcher />
							</div>

							<div
								className={`burger-menu-icon-block`}
								onClick={() => toggleBurgerMenu()}>
								<LottieAnimation
									loop={false}
									autoplay={false}
									isPlay={isBurgerMenuOpen}
									animData={burgerBlueLottie}
									isReverse={!isBurgerMenuOpen}
								/>
							</div>
						</div>
					</div>

					<div className='burger-content-wrapper'>
						<div className='burger-content-inner-wrapper'>
							<CustomLink
								url={config.routes.theTrail.path}
								className={`burger-menu-item-block ${pathname.replace(selectedLang + '/', '') == config.routes.theTrail.path ? 'active' : ''}`}>
								<Text
									className={`h5 font-montserrat-medium blue-1000-color burger-menu-item`}
									text={config.routes.theTrail.name}
								/>
							</CustomLink>

							<MenuItemCollapse
								title={config.routes.regions.name}
								url={config.routes.regions.path}
								children={globalData.regions}
							/>

							<MenuItemCollapse
								title={config.routes.usefulInfo.name}
								url={config.routes.usefulInfo.path}
								children={globalData.useful_informations}
							/>

							<CustomLink
								url={config.routes.news.path}
								className={`burger-menu-item-block ${pathname.replace(selectedLang + '/', '') == config.routes.news.path ? 'active' : ''}`}>
								<Text
									className={`h5 font-montserrat-medium blue-1000-color burger-menu-item`}
									text={config.routes.news.name}
								/>
							</CustomLink>

							<CustomLink
								url={config.routes.about.path}
								className={`burger-menu-item-block ${pathname.replace(selectedLang + '/', '') == config.routes.about.path ? 'active' : ''}`}>
								<Text
									className={`h5 font-montserrat-medium blue-1000-color burger-menu-item`}
									text={config.routes.about.name}
								/>
							</CustomLink>

							<CustomLink
								url={config.routes.contact.path}
								className={`burger-menu-item-block ${pathname.replace(selectedLang + '/', '') == config.routes.contact.path ? 'active' : ''}`}>
								<Text
									className={`h5 font-montserrat-medium blue-1000-color burger-menu-item`}
									text={config.routes.contact.name}
								/>
							</CustomLink>
						</div>
					</div>
				</div>
			</BurgerMenuStyle>

			<BottomTabNavigation />
		</>
	) : null;
};

export default withLanguageContext(withDataContext(withUIContext(BurgerMenu, ['isBurgerMenuOpen', 'toggleBurgerMenu']), ['globalData']), ['selectedLang']);
